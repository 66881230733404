var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "room-detail standings-card", attrs: { grow: "" } },
    [
      _c("div", {
        staticClass: "room-detail-image",
        staticStyle: {
          "background-image":
            "url('https://cdn.shopify.com/s/files/1/0135/1827/4660/articles/iStock-832096220_775x.jpg?v=1554562772')",
          opacity: "0.6",
        },
      }),
      _c(
        "div",
        {
          staticClass: "room-detail-info rtb-d-flex flex-column h-0 flex-grow",
        },
        [
          _c(
            "v-layout",
            { attrs: { row: "", shrink: "" } },
            [
              _c("v-flex", [
                _c("div", { staticClass: "main-title" }, [_vm._v("STANDINGS")]),
              ]),
              _c(
                "v-flex",
                {
                  staticStyle: { "margin-left": "auto" },
                  attrs: { shrink: "" },
                },
                [
                  !_vm.isUserscore && !_vm.loading
                    ? _c(
                        "v-btn",
                        {
                          attrs: { title: "Download", icon: "", dark: "" },
                          on: { click: _vm.downloadCSV },
                        },
                        [
                          _c("SvgIcon", {
                            attrs: {
                              name: "download",
                              width: "20",
                              height: "20",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function (ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    attrs: {
                                      title: "Close",
                                      icon: "",
                                      dark: "",
                                    },
                                    on: { click: _vm.onCloseStandings },
                                  },
                                  on
                                ),
                                [
                                  _c("SvgIcon", {
                                    attrs: {
                                      name: "times-regular",
                                      width: "20",
                                      height: "20",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_c("span", [_vm._v("Close Standings")])]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("br"),
          _c(
            "v-layout",
            {
              staticClass: "h-0",
              staticStyle: { overflow: "auto" },
              attrs: { column: "" },
            },
            [
              _vm.isTournament
                ? _vm._l(_vm.tournamentScores, function (team, i) {
                    return _c(
                      "v-flex",
                      { key: i, attrs: { "align-center": "" } },
                      [
                        _vm._v(" " + _vm._s(team.name) + ": "),
                        _c("span", [_vm._v(" " + _vm._s(team.score))]),
                      ]
                    )
                  })
                : [
                    _vm.isUserscore
                      ? _c("UserList", {
                          staticClass: "max-h-full",
                          attrs: {
                            users: _vm.usersWithScore,
                            size: _vm.userListRowLength,
                            showScore: "",
                            step: 200,
                          },
                          on: { onSelect: _vm.onSelect },
                        })
                      : _c(
                          "v-flex",
                          [
                            !_vm.loading
                              ? _c(
                                  "table",
                                  { staticClass: "ml-auto mr-auto" },
                                  [
                                    _c("colgroup", [
                                      _c("col", {
                                        staticStyle: { width: "4ch" },
                                      }),
                                      _c("col", {
                                        staticStyle: { width: "10ch" },
                                      }),
                                      _c("col"),
                                      _c("col", {
                                        staticStyle: { width: "8ch" },
                                      }),
                                    ]),
                                    _vm._l(_vm.teams, function (team, i) {
                                      return _c("tr", { key: i }, [
                                        _c("td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.numSuffix(i + 1)) +
                                              " "
                                          ),
                                        ]),
                                        _c(
                                          "td",
                                          { staticStyle: { color: "#e8e9eb" } },
                                          [
                                            _vm._v(
                                              " " + _vm._s(team.name) + " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { staticStyle: { color: "#9aa1ad" } },
                                          [
                                            _vm._v(
                                              " " + _vm._s(team.gameName) + " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(team.score) + " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    }),
                                  ],
                                  2
                                )
                              : _c(
                                  "v-flex",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "text-align": "center",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        "margin-top": "90px",
                                        width: "100px",
                                      },
                                      attrs: {
                                        src: require("@/assets/loading-12.gif"),
                                      },
                                    }),
                                  ]
                                ),
                          ],
                          1
                        ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }