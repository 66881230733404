<template>
  <v-flex class="room-detail standings-card" grow>
    <div
      class="room-detail-image"
      style="
        background-image: url('https://cdn.shopify.com/s/files/1/0135/1827/4660/articles/iStock-832096220_775x.jpg?v=1554562772');
        opacity: 0.6;
      "
    ></div>
    <div class="room-detail-info rtb-d-flex flex-column h-0 flex-grow">
      <v-layout row shrink>
        <v-flex>
          <div class="main-title">STANDINGS</div>
        </v-flex>

        <v-flex shrink style="margin-left: auto">
          <v-btn
            v-if="!isUserscore && !loading"
            title="Download"
            icon
            dark
            @click="downloadCSV"
          >
            <SvgIcon name="download" width="20" height="20" />
          </v-btn>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                title="Close"
                icon
                dark
                v-on="on"
                @click="onCloseStandings"
              >
                <SvgIcon name="times-regular" width="20" height="20" />
              </v-btn>
            </template>
            <span>Close Standings</span>
          </v-tooltip>
        </v-flex>
      </v-layout>
      <br />
      <v-layout column class="h-0" style="overflow: auto">
        <template v-if="isTournament">
          <v-flex align-center v-for="(team, i) in tournamentScores" :key="i">
            {{ team.name }}: <span> {{ team.score }}</span>
          </v-flex>
        </template>
        <template v-else>
          <UserList
            v-if="isUserscore"
            :users="usersWithScore"
            :size="userListRowLength"
            showScore
            :step="200"
            class="max-h-full"
            @onSelect="onSelect"
          />
          <v-flex v-else>
            <table v-if="!loading" class="ml-auto mr-auto">
              <colgroup>
                <col style="width: 4ch" />
                <col style="width: 10ch" />
                <col />
                <col style="width: 8ch" />
              </colgroup>
              <tr v-for="(team, i) in teams" :key="i">
                <td>
                  {{ numSuffix(i + 1) }}
                </td>
                <td style="color: #e8e9eb">
                  {{ team.name }}
                </td>
                <td style="color: #9aa1ad">
                  {{ team.gameName }}
                </td>
                <td style="text-align: right">
                  {{ team.score }}
                </td>
              </tr>
            </table>
            <v-flex v-else style="width: 100%; text-align: center">
              <img
                style="margin-top: 90px; width: 100px"
                :src="require('@/assets/loading-12.gif')"
              />
            </v-flex>
          </v-flex>
        </template>
      </v-layout>
    </div>
  </v-flex>
</template>

<script>
import { mapGetters } from "vuex"
import { Role } from "@/helpers"
import { fetchGameTeamsObject } from "@/services/game.service"
import { db } from "@/firebase"
import Team from "@shared/Team"
import { ExportToCsv } from "export-to-csv"

import UserList from "@/components/Lobby/UserList.vue"

export default {
  name: "LobbyGameScoreboard",
  components: {
    UserList
  },
  props: {
    game: {
      type: Object,
      required: true
    },
    games: {
      type: Array,
      default: () => []
    },
    tournament: {
      type: Object
    }
  },
  data() {
    return {
      teams: [],
      usersScore: {},
      loading: true
    }
  },
  async created() {
    this.clientUsersScoreRef?.on("value", this.onUsersScoreUpdate)

    const teams = []
    const games = this.games

    if (games) {
      for (let i in games) {
        const game = this.games[i]
        const obj = await fetchGameTeamsObject({
          orgID: this.user.orgID,
          gameID: game.id
        })

        const array = Team.normalize(obj || {})
          .map(team => ({
            name: team.name,
            score: team.totalScore,
            gameName: game.externalName
          }))
          .filter(team => team.name && team.score > 0)

        teams.push(...array)
      }
    }

    this.teams = teams.sort((a, b) => b.score - a.score)
    this.loading = false
  },
  beforeDestroy() {
    this.clientUsersScoreRef?.off("value", this.onUsersScoreUpdate)
  },
  computed: {
    ...mapGetters("auth", ["user", "client", "clientID"]),
    userListRowLength() {
      const width = parseInt(this.$window.width)
      if (width < 1350) return 4
      if (width < 1450) return 5
      if (width < 1520) return 6
      if (width < 1700) return 7
      return 8
    },
    gameName() {
      return this.game.externalName || this.game.name
    },
    clientUsersScoreRef() {
      return db
        .auxiliary()
        .ref(`client/${this.clientID}/userscore`)
        .limitToFirst(5000)
    },
    isUserscore() {
      return this.client?.userscoreEnabled ?? false
    },
    usersWithScore() {
      const users = this.$store.state.allusers.users
      const usersScore = this.usersScore || {}
      return _.chain(users)
        .filter(user => user && user.role === Role.Player)
        .map(user => ({
          ...user,
          score: usersScore[user.id] ?? 0
        }))
        .filter(user => parseInt(user.score))
        .orderBy("score", "desc")
        .value()
    },
    isTournament() {
      return this.tournament && this.allGames.some(game => game.tournamentID)
    },
    allGames() {
      return this.$store.state.pregame.games.filter(
        game =>
          (!game.gameType ||
            game.gameType === "Standard" ||
            game.gameType === "Tournament") &&
          !game.deletedTimestamp
      )
    },
    tournamentScores() {
      return Object.entries(this.tournament?.games || {}).reduce(
        (acc, [gameID, game]) => {
          Object.entries(game.teams).forEach(([teamID, { score, name }]) => {
            if (acc[teamID]) {
              acc[teamID].score += score
            } else {
              acc[teamID] = { score, name }
            }
          })
          return acc
        },
        {}
      )
    }
  },
  methods: {
    onCloseStandings() {
      this.$emit("onCloseStandings")
    },
    onUsersScoreUpdate(snapShot) {
      this.usersScore = snapShot?.val() ?? {}
    },
    onSelect(obj) {
      this.$emit("onSelect", obj)
    },
    numSuffix(num) {
      if (num === 1) {
        return `${num}st`
      } else if (num === 2) {
        return `${num}nd`
      } else if (num === 3) {
        return `${num}rd`
      } else {
        return `${num}th`
      }
    },
    downloadCSV() {
      const fieldsMap = {
        position: "Position",
        name: "Name",
        gameName: "Game Name",
        score: "Score"
      }
      const options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: false,
        filename: "Scoreboard",
        useTextFile: false,
        useBom: true,
        headers: Object.values(fieldsMap)
      }
      const csvExporter = new ExportToCsv(options)

      const data = this.teams.map(({ name, gameName, score }, index) => ({
        position: this.numSuffix(index + 1),
        name,
        gameName,
        score
      }))

      csvExporter.generateCsv(data)
    }
  }
}
</script>

<style lang="scss">
.standings-card {
  .--with-onboarding-overlay::after {
    display: none;
  }
}
</style>
